<template>
<div class="card-box-full">
  <div class="card-box-header">
    <table width="100%">
      <tr>
        <td align="left">
          Block Lists
        </td>
        <td align="right">
        </td>
      </tr>
    </table>


  </div>

  <div v-if="errorMessage">
    <div class="alert alert-danger" style="margin-top: 15px; margin-bottom: 5px" role="alert">{{ errorMessage }}</div>
  </div>

  <!-- --------------------------------------- -->
  <div v-if="isDownloadingLists">
    <div style="height: 15px"></div>
    <div class="spinner-border spinner-border-sm text-primary" role="status">
      <span class="sr-only"></span>
    </div>
  </div>

  <!-- --------------------------------------- -->
  <div v-else style="overflow-x: auto;">
    <table class="table table-responsive-sm" style="margin-top: 10px; margin-bottom: 0px;">
      <thead>
        <tr style="font-size: 13px; border: 2px solid transparent">
          <th style="text-align:left">Prefix</th>
          <th style="text-align:left">Type</th>
          <th style="text-align:left">Update</th>
          <th style="text-align:center">Size</th>
          <th style="text-align:center">File</th>
        </tr>
      </thead>
      <tbody style="font-size: 13px">
        <tr v-for="list in blocklists">
          <td>+{{ list.prefix }}</td>
          <td>{{ list.type }}</td>
          <td>
            <timeago :datetime="list.updated"></timeago>
          </td>
          <td style="text-align:center">{{ parseInt(list.size / 1024) }} kb</td>
          <td style="text-align:center">
            <CButton :disabled="isDownloadingFile" @click="downloadFile(list)" color="info" style="margin-top: 0px">
              Download
            </CButton>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

</div>
</template>


<script>
export default {
  name: 'Block-Lists',
  data() {
    return {
      errorMessage: null,
      isDownloadingLists: false,
      isDownloadingFile: false,
      blocklists: []
    }
  },
  methods: {
    //--------------------------------------------------------------------------
    downloadLists() {

      if (this.isDownloadingLists) {
        return;
      } else {
        this.errorMessage = null;
        this.isDownloadingLists = true;
      }

      // Fetch the block lists
      var that = this;
      var method = "GET";
      var url = "https://number-classifier.platform.robocalls.ai/blacklists";
      var requiresSession = true;
      RobocallsAI.shared().execute(method, url, null, requiresSession, {
        onSuccess: function(code, body) {
          // Code = HTTP status code
          // Body = Response from server
          that.isDownloadingLists = false;
          if (code != 200) {
            // Something went wrong
            var message = that.parseMessage(body);
            if (message) {
              that.errorMessage = message;
            } else {
              that.errorMessage = "Received a code " + code + " from the service";
            }
          } else {
            // Parse the lists
            try {
              var result = JSON.parse(body);
              that.processLists(result);
            } catch (err) {
              that.errorMessage = "Failed to parse response from the server";
            }
          }
        },
        onCancelled: function() {
          // Fired if the request was cancelled
          that.isDownloadingLists = false;
        },
        onFailure: function(error) {
          // Fired if the request fails
          that.isDownloadingLists = false;
          that.errorMessage = error;
        }
      })
    },
    //--------------------------------------------------------------------------
    downloadFile(list) {

      // Make sure we have a prefix
      if (!list.prefix) {
        this.errorMessage = "File does not have a prefix";
        return;
      }

      // Set the URL to fetch
      if (list.type == "master") {
        var url = "https://number-classifier.platform.robocalls.ai/blacklist-master-file?prefix=" + encodeURIComponent(list.prefix);
      } else if (list.type == "diff") {
        var url = "https://number-classifier.platform.robocalls.ai/blacklist-diff-file?prefix=" + encodeURIComponent(list.prefix);
      } else {
        this.errorMessage = "Unknown file type: " + list.type;
        return;
      }

      if (this.isDownloadingFile) {
        return;
      } else {
        this.errorMessage = null;
        this.isDownloadingFile = true;
      }

      // Fetch the file
      var that = this;
      var method = "GET";
      var requiresSession = true;
      RobocallsAI.shared().execute(method, url, null, requiresSession, {
        onSuccess: function(code, body) {
          // Code = HTTP status code
          // Body = Response from server
          that.isDownloadingFile = false;
          if (code != 200) {
            // Something went wrong
            var message = that.parseMessage(body);
            if (message) {
              that.errorMessage = message;
            } else {
              that.errorMessage = "Received a code " + code + " from the service";
            }
          } else {
            // Parse the lists
            try {
              that.processFile(list, body);
            } catch (err) {
              that.errorMessage = "Failed to parse response from the server";
            }
          }
        },
        onCancelled: function() {
          // Fired if the request was cancelled
          that.isDownloadingFile = false;
        },
        onFailure: function(error) {
          // Fired if the request fails
          that.isDownloadingFile = false;
          that.errorMessage = error;
        }
      })
    },
    //--------------------------------------------------------------------------
    parseMessage(body) {
      try {
        var result = JSON.parse(body);
        return result.message;
      } catch (err) {
        return "";
      }
    },
    //--------------------------------------------------------------------------
    processFile(list, body) {
      const blob = new Blob([body], {
        type: 'text/plain'
      })
      const e = document.createEvent('MouseEvents'), a = document.createElement('a');
      a.download = "blocklist_" + list.type + "_" + list.prefix + ".txt";
      a.href = window.URL.createObjectURL(blob);
      a.dataset.downloadurl = ['text/json', a.download, a.href].join(':');
      e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
      a.dispatchEvent(e);
    },
    //--------------------------------------------------------------------------
    processLists(response) {
      // Make sure we received results
      if (!response || !response.blacklists || response.blacklists.length == 0) {
        this.errorMessage = "No lists were returned";
        return;
      }

      // Sort the lists
      function compare(a, b) {
        if (parseInt(a.prefix) > parseInt(b.prefix)) {
          return 1;
        } else if (parseInt(a.prefix) == parseInt(b.prefix)) {
          if (a.type == "master") {
            return -1;
          }
        }
        return 0;
      }
      response.blacklists.sort(compare);

      // Store the lists
      this.blocklists = response.blacklists;
    }
  },
  mounted() {
    this.downloadLists();
  },
}
</script>
